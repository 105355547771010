import React from 'react';
import "./Section.styles.css"
import { Button,Card,Row,Col } from 'react-bootstrap';

/**
 * 
 */
const Section = ({title, content, image,handleButton}) => {
    return <div>
        <Row>
            <Col>
                <Card
                    bg={"light"}
                    key={1}
                    text={'dark'}
                    style={{height:"100%",minWidth: "200px"}}
                    className="mb-2"
                >
                    <Card.Body>
                    <Card.Text style={{textAlign: "justify"}}>
                        {content}

                    </Card.Text>
                    <Row className="justify-content-md-right">
                        <Col></Col>
                        <Col><Button onClick={handleButton} variant="outline-info">Read More</Button></Col>
                        
                    </Row>
                   

                    </Card.Body>
                </Card>
            </Col>
            <Col className="justify-content-md-center"                     style={{height:"100%",minWidth: "200px"}}
>
                <Card.Img
                        className="d-block"
                        src={image}
                        alt="Second slide"
                        height="400px"
                    />
            </Col>
        </Row>

    </div>;
}

export default Section;